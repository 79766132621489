import SignInForm from "./components/features/SignInForm/SignInForm";
import Button from "./components/common/Button/Button";
import "./App.css";
import Spinner from "./components/common/Spinner/Spinner";

function App() {
  return (
    <div
      className="App"
      style={{
        height: "70vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <h1>frugoal</h1>
        <h2 style={{ marginBottom: 80 }}>saving made simple.</h2>
        <a href="https://www.michaelashe.dev">
          <Button text="coming soon"></Button>
        </a>

        <Spinner />
      </div>
    </div>
  );
}

export default App;
