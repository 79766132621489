import React from "react";
import styles from "./Spinner.module.css";

const Spinner = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 80,
      }}
    >
      <span className={styles.loader}></span>
    </div>
  );
};

export default Spinner;
